define("ember-svg-jar/inlined/CHINOKI_Logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.307 15.831v-2.494H7.224c-1.543 0-2.754-.505-3.632-1.517-.785-.904-1.158-2.009-1.118-3.313.053-1.237.473-2.275 1.258-3.113.89-.945 2.055-1.417 3.492-1.417h8.083V1.482H7.224c-2.315 0-4.138.732-5.468 2.196C.586 4.968 0 6.578 0 8.508c0 1.955.612 3.631 1.836 5.029 1.357 1.53 3.153 2.294 5.388 2.294zM20.237 1.482H17.74v14.35h2.496zm1.116 5.888v2.495h8.442v5.966h2.495V1.482h-2.495V7.37zM37.672 1.482h-2.495v14.35h2.495z\" fill=\"#45ab73\"/><path d=\"M53.598 15.97c.626 0 .939-.458.939-1.375V1.482h-2.495v9.959l-9.2-9.22c-.48-.492-.952-.739-1.417-.739-.32 0-.58.114-.78.34-.198.226-.298.532-.298.918V15.83h2.495V5.865l9.2 9.248c.571.572 1.09.858 1.556.858M68 15.831c2.236 0 4.031-.764 5.389-2.294 1.224-1.398 1.836-3.074 1.836-5.03 0-1.929-.592-3.538-1.776-4.83C72.13 2.215 70.316 1.483 68 1.483h-4.012c-2.314 0-4.138.732-5.469 2.196-1.17 1.29-1.756 2.9-1.756 4.83 0 1.955.612 3.631 1.837 5.029 1.357 1.53 3.153 2.294 5.388 2.294zm0-11.854c1.438 0 2.595.472 3.473 1.417.798.838 1.218 1.876 1.258 3.113.053 1.304-.313 2.409-1.098 3.313-.878 1.012-2.09 1.517-3.632 1.517h-4.012c-1.543 0-2.753-.505-3.632-1.517-.785-.904-1.158-2.009-1.118-3.313.054-1.237.473-2.275 1.259-3.113.89-.945 2.054-1.417 3.491-1.417zM80.972 9.884c-.478-.412-.718-.791-.718-1.137 0-.359.24-.745.718-1.158l7.125-6.107h3.692l-8.561 7.325 8.561 7.024h-3.692zm-1.196-8.402h-2.495v14.35h2.495zM96.652 1.482h-2.494v14.35h2.494zM98.875.868h.105c.122 0 .221-.039.221-.133 0-.083-.064-.138-.204-.138a.52.52 0 00-.122.01zm-.005.508h-.222V.47c.087-.011.21-.028.367-.028.181 0 .263.028.327.072a.225.225 0 01.1.193c0 .11-.088.183-.2.216v.011c.094.028.14.1.17.221a.8.8 0 00.07.221h-.233c-.03-.033-.047-.11-.076-.22-.018-.095-.076-.139-.199-.139h-.104zm-.59-.47c0 .404.321.725.747.725a.713.713 0 00.729-.719.724.724 0 00-.74-.73.725.725 0 00-.735.724m1.72 0c0 .51-.432.912-.98.912-.547 0-.985-.403-.985-.912 0-.508.438-.906.991-.906.542 0 .974.398.974.906\" fill=\"#424447\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100.001 15.971"
    }
  };
});