define("client-base/components/cb-environment/component", ["exports", "@glimmer/component", "@ember/application", "@ember/utils"], function (_exports, _component, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * determine the visibility of the content block by the environment<br>
   * Note: usage of both only and not is generally not useful<br>
   * If there is no content block print the name of the environment
   * 
   * @class CbEnvironmentComponent
   * @param {string[] | string} only show the content block only in these environments
   * @param {string[] | string} not show content block not in these environment
   * @param {boolean} and don't show content if false, even if only or not are satisfied
   * @param {boolean} or show content if true, even if only or not are not satisfied
   * @param {boolean} [print=false] instead of the content block print the name of the environment
   */
  class CbEnvironmentComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "environment", 'no environment');

      const environment = (0, _application.getOwner)(this)?.resolveRegistration('config:environment') ?? {};
      this.environment = environment['environment'] ?? this.environment;
    }

    get only() {
      let only = this.args.only ?? [];
      if (typeof only === 'string' || only instanceof String) only = [only];
      return only;
    }

    get not() {
      let not = this.args.not ?? [];
      if (typeof not === 'string' || not instanceof String) not = [not];
      return not;
    }

    get or() {
      return this.args.or ?? false;
    }

    get and() {
      return this.args.and ?? true;
    }

    get show() {
      if (!this.and) return false;else if (this.or) return true;
      if (this.only.includes(this.environment)) return true;
      if (this.not.includes(this.environment)) return false; // if only has strings, but we are not included, we should not be shown

      if (!(0, _utils.isEmpty)(this.only)) return false; // if not has strings, but we are not included, we should be shown

      if (!(0, _utils.isEmpty)(this.not)) return true;
      throw new Error("If you reached this point the end is quite near. Prepare for the reign of the Unspoken Name.");
    }

  }

  _exports.default = CbEnvironmentComponent;
});