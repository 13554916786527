define("client-base/components/cb-click-icon/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a ...attributes
  		href="#"
  		{{on "click" @onClick}}
  		class={{concat
  				"align-self-" (if @align @align "center")
  				(if (eq @pull "right") " ms-auto")
  		}}
  >
  	{{#if @icon}}
  		<FaIcon
  				@size={{@size}}
  				@icon={{@icon}}
  				@flip={{@flip}}
  				{{!-- we leave the class symbol for historical reasons here, in the code of ebo-client it's use everywhere --}}
  				class={{concat "cb-click-icon symbol "
  						(if @danger "danger ")
  						(if @selected "selected ")
  						(if @selected2 "selected2 ")
  						@classIcon
  				}}
  				@pull={{@pull}}/>
  	{{else if @svg}}
  		{{!-- we leave the class symbol for historical reasons here, in the code of ebo-client it's use everywhere --}}
  		{{svg-jar @svg
  				class=(concat "cb-click-icon symbol svg-inline--fa "
  						(if (eq @pull "right") "fa-pull-right ")
  						(if @selected "selected ")
  						(if @selected2 "selected2 ")
  						@classIcon
  				)
  		}}
  	{{/if}}
  	{{yield}}
  </a>
  */
  {
    "id": "pLYkuB4N",
    "block": "[[[11,3],[17,1],[24,6,\"#\"],[16,0,[28,[37,0],[\"align-self-\",[52,[30,2],[30,2],\"center\"],[52,[28,[37,2],[[30,3],\"right\"],null],\" ms-auto\"]],null]],[4,[38,3],[\"click\",[30,4]],null],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"\\t\\t\"],[8,[39,4],[[16,0,[28,[37,0],[\"cb-click-icon symbol \",[52,[30,6],\"danger \"],[52,[30,7],\"selected \"],[52,[30,8],\"selected2 \"],[30,9]],null]]],[[\"@size\",\"@icon\",\"@flip\",\"@pull\"],[[30,10],[30,5],[30,11],[30,3]]],null],[1,\"\\n\"]],[]],[[[41,[30,12],[[[1,\"\\t\\t\"],[1,[28,[35,5],[[30,12]],[[\"class\"],[[28,[37,0],[\"cb-click-icon symbol svg-inline--fa \",[52,[28,[37,2],[[30,3],\"right\"],null],\"fa-pull-right \"],[52,[30,7],\"selected \"],[52,[30,8],\"selected2 \"],[30,9]],null]]]]],[1,\"\\n\\t\"]],[]],null]],[]]],[1,\"\\t\"],[18,13,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@align\",\"@pull\",\"@onClick\",\"@icon\",\"@danger\",\"@selected\",\"@selected2\",\"@classIcon\",\"@size\",\"@flip\",\"@svg\",\"&default\"],false,[\"concat\",\"if\",\"eq\",\"on\",\"fa-icon\",\"svg-jar\",\"yield\"]]",
    "moduleName": "client-base/components/cb-click-icon/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});