define("ember-svg-jar/inlined/school", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><g id=\"d\"><g id=\"b\"><path id=\"a\" d=\"M0-1l-.3 1 .5.1z\"/><use xlink:href=\"#a\" transform=\"scale(-1 1)\"/></g><g id=\"c\"><use xlink:href=\"#b\" transform=\"rotate(72)\"/><use xlink:href=\"#b\" transform=\"rotate(144)\"/></g><use xlink:href=\"#c\" transform=\"scale(-1 1)\"/></g></defs><path fill=\"#039\" d=\"M0 0h640v480H0z\"/><g fill=\"#fc0\" transform=\"translate(320 242.3) scale(23.7037)\"><use xlink:href=\"#d\" width=\"100%\" height=\"100%\" y=\"-6\"/><use xlink:href=\"#d\" width=\"100%\" height=\"100%\" y=\"6\"/><g id=\"e\"><use xlink:href=\"#d\" width=\"100%\" height=\"100%\" x=\"-6\"/><use xlink:href=\"#d\" width=\"100%\" height=\"100%\" transform=\"rotate(-144 -2.3 -2.1)\"/><use xlink:href=\"#d\" width=\"100%\" height=\"100%\" transform=\"rotate(144 -2.1 -2.3)\"/><use xlink:href=\"#d\" width=\"100%\" height=\"100%\" transform=\"rotate(72 -4.7 -2)\"/><use xlink:href=\"#d\" width=\"100%\" height=\"100%\" transform=\"rotate(72 -5 .5)\"/></g><use xlink:href=\"#e\" width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 640 480",
      "data-ember-extension": "1"
    }
  };
});