define("ember-svg-jar/inlined/CHI_Logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M33.232 31.152v-5.415H15.685c-3.352 0-5.98-1.098-7.886-3.293-1.705-1.964-2.513-4.362-2.426-7.193.115-2.686 1.025-4.939 2.73-6.759 1.934-2.05 4.462-3.076 7.582-3.076h17.547V0H15.685C10.659 0 6.7 1.589 3.813 4.766 1.27 7.568 0 11.063 0 15.25c0 4.246 1.328 7.886 3.986 10.919 2.946 3.322 6.846 4.982 11.699 4.982h17.547M43.934 0h-5.416v31.152h5.416zm2.427 12.782v5.416h18.327v12.954h5.416V0h-5.416v12.782H46.361M76.372 31.152h5.416V0h-5.416z\" fill=\"#44a972\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 81.788 31.152"
    }
  };
});