define("ember-svg-jar/inlined/benchmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" d=\"M41.27 10.666h429.46c16.954 0 30.604 11.987 30.604 26.876v148.916c0 14.89-13.65 26.876-30.604 26.876H41.27c-16.954 0-30.604-11.987-30.604-26.876V37.542c0-14.89 13.65-26.876 30.604-26.876z\" fill=\"none\" stroke-width=\"21.333\" stroke-linejoin=\"round\"/><path d=\"M32 224c-17.7 0-32-13.128-32-29.377V29.377C0 13.127 14.3 0 32 0h96v224z\" fill=\"currentColor\"/><path stroke=\"currentColor\" d=\"M41.27 298.666h429.46c16.954 0 30.604 11.987 30.604 26.876v148.916c0 14.89-13.65 26.875-30.604 26.875H41.27c-16.954 0-30.604-11.986-30.604-26.875V325.542c0-14.89 13.65-26.875 30.604-26.875z\" fill=\"none\" stroke-width=\"21.333\" stroke-linejoin=\"round\"/><path d=\"M32 512c-17.7 0-32-13.128-32-29.377V317.377C0 301.127 14.3 288 32 288h96v224z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 512 512",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});