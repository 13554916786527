define("ember-svg-jar/inlined/favicon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M33.232 56.47v-5.416H15.685c-3.352 0-5.98-1.097-7.886-3.293-1.705-1.964-2.513-4.36-2.426-7.192.115-2.686 1.025-4.94 2.73-6.76 1.934-2.05 4.462-3.075 7.582-3.075h17.547v-5.416H15.685c-5.026 0-8.984 1.589-11.872 4.766C1.27 32.886 0 36.38 0 40.569c0 4.246 1.328 7.886 3.986 10.919 2.946 3.322 6.846 4.982 11.699 4.982h17.547M43.934 25.318h-5.416V56.47h5.416zm2.427 12.781v5.416h18.327V56.47h5.416V25.318h-5.416v12.781H46.361M76.372 56.47h5.416V25.318h-5.416z\" fill=\"#44a972\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 81.788 81.788"
    }
  };
});